import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import {  HttpHeaders, HttpErrorResponse } from '@angular/common/http';
@Injectable()
export class HomeService {

    constructor(private http: HttpClient) { }
 
    getEvent(page): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/get-event?page=` + page).pipe(map(user => user));
    }


    getReason(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/hold-reason` ).pipe(map(user => user));
    }


    getStudents(page, search, institute,startdate,enddate): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/users-photos?page=`+ page + '&search=' + search + '&institution=' + institute+'&start_date='+startdate+'&end_date='+enddate).pipe(map(user => user));
    }

    getPostalStudents(page, search, institute,satrtdate,enddate): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/postals-users-photos?page=`+ page + '&search=' + search + '&institution=' + institute+'&start_date='+satrtdate+'&end_date='+enddate).pipe(map(user => user));
    }

    getAproveduser(slotId, roll_id, page, search, institution, course, degree, roll_no, status): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/registered-users?slotId=` + slotId + '&roll_id=' + roll_id +'&page=' + page + '&search=' + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no + '&status=' + status).pipe(map(user => user));
    }

    getUsercount(slotId): Observable<any> {
        
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/admin/approved-users-count?slotId=`+slotId).pipe(map(user => user));
    }

    getInstitute(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/institute`).pipe(map(user => user));
    }

    getCourse(id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/course/institute/` +id).pipe(map(user => user));
    }

    getDegree(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/degree`).pipe(map(user => user));
    }

    getImages(page): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/gallery?page=`+page).pipe(map(user => user));
    }

    getImagesById(id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/gallery/`+id).pipe(map(user => user));
    }

    getFeedsById(id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/feeds/`+id).pipe(map(user => user));
    }

    getFeeds(page): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/feeds?page=`+page).pipe(map(user => user));
    }

    getYoutubeLink(page): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/youtube-link?page=`+page).pipe(map(user => user));
    }

    uploadImages(images:File[]) {
        const formData: FormData = new FormData();
        if(images.length>0){
        images && images.map(image => {
          formData.append('image', image, image.name);
        });
      }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/gallery/create`, formData).pipe(map(user => {
            return user;
        }));
    } 

    uploadFeeds(images:File[],title,desc,type,link) {
        const formData: FormData = new FormData();
        if(images.length>0){
        images && images.map(image => {
          formData.append('image', image, image.name);
        });
      }
      formData.append('title', title);
      formData.append('type', type);
      formData.append('desc', desc);
      formData.append('link', link);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/feeds/create`, formData).pipe(map(user => {
            return user;
        }));
    } 

    uploadLinks(link) {
        const formData: FormData = new FormData();
      formData.append('link', link);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/youtube-link/create`, formData).pipe(map(user => {
            return user;
        }));
    } 

    updateLinks(id,link) {
        const formData: FormData = new FormData();
      formData.append('link', link);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/youtube-link/update/`+id, formData).pipe(map(user => {
            return user;
        }));
    } 

    updateFeeds(id,title,desc,status,link,type,images?:File[],viewimage?:any[]) {
        const formData: FormData = new FormData();
        if(images.length>0){
        images && images.map(image => {
          formData.append('image', image, image.name);
        });
      }
      else
{
    // viewimage && viewimage.map(image => {
    formData.append('image', JSON.stringify(viewimage));
//    });
 
}

      formData.append('title', title);
      formData.append('desc', desc);
      formData.append('status', status);
      formData.append('link', link);
      formData.append('type', type);
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/feeds/update/`+id, formData).pipe(map(user => {
            return user;
        }));
    } 


    deleteImage(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/gallery/delete/` + id).pipe(map(user => {
            return user;
        }));
    }

    deleteFeeds(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/feeds/delete/` + id).pipe(map(user => {
            return user;
        }));
    }

    deleteLinks(id: string) {
        return this.http.delete<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/pr/youtube-link/delete/` + id).pipe(map(user => {
            return user;
        }));
    }

    updateUser(userid:any,status, reason,movebackreason,eventId,slotId) {
        var formData = {
            'status': status,
            'reason':reason,
            'move_back_reason':'',
            'event':eventId,
            'slotId':slotId
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/institute-user-status/` + userid, formData).pipe(map(user => {
            return user;
        }));
    }

    updateRoeUser(userid:any,status, reason,movebackreason,eventid,slotid) {
        var formData = {
            'status': status,
            'reason':reason,
            'move_back_reason':movebackreason,
            'event':eventid,
            'slotId':slotid
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/roe-user-status/` + userid, formData).pipe(map(user => {
            return user;
        }));
    }

    updateDateUser(userid:any,month,year,eventid,slotid) {
        var formData = {
            'month': month,
            'year': year,
            'event':eventid,
            'slotId':slotid
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/month-year-update/` + userid ,formData).pipe(map(user => {
            return user;
        }));
    }

    getregcount(slotId, roll_id, page, search, institution, course, degree, roll_no): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/registered-users-counts?slotId=` + slotId + '&roll_id=' + roll_id +'&page=' + page + '&search=' + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no).pipe(map(user => user));
    }

    getInstitutesingle(id:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/institute/`+id).pipe(map(user => user));
    }


    getUserById(id:any): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/user-details/`+id).pipe(map(user => user));
    }

    updateRegistartionList(id:any,reg_no,name,institution,course,dob,gender,
        month_of_passing,year_of_passing,address,city,country,pincode,email,
        mobile_no,adhaar_no,date_internship,eventid,slotid,file,profilephoto
) {
    const formData: FormData = new FormData();
 
    formData.append('registration_no',reg_no);
    formData.append('full_name',name);
    formData.append('institution',institution);
    formData.append('course',course);
    formData.append('date_of_birth',dob);
    formData.append('gender',gender);
    formData.append('month_of_pass',month_of_passing);
    formData.append('year_of_pass',year_of_passing);
    formData.append('address',address);
    formData.append('city',city);
    formData.append('country',country);
    formData.append('pincode',pincode);
    formData.append('email',email);
    formData.append('mobile',mobile_no);
    formData.append('adhaar_no',adhaar_no);
    formData.append('internship_c_date',date_internship)
    formData.append('event',eventid)
    formData.append('slotId',slotid)
    if (file && file.name) {
        formData.append('profile_photo', file, file.name);
      }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/registration-update/` + id, formData).pipe(map(user => {
            return user;
        }));
    }

    downloaduser(slotId, roll_id, search, institution, course, degree, roll_no,status): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/users-export-excel?slotId=` + slotId + '&roll_id=' + roll_id + '&search=' + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no+ '&status=' + status, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadconvocationreport(slotId): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/reports/totalBookedStudents?slotId=` + slotId, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadinstitutereport(slotId): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/reports/inst-wise?slotId=` + slotId, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloaddegreereport(slotId): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/reports/degree-wise?slotId=` + slotId, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadImages(institution,startdate,enddate): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/users-photos-download?institution=` + institution +'&start_date='+startdate+'&end_date='+enddate , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadPostalImages(institution,startdate,enddate): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/postals-photos-download?institution=` + institution +'&start_date='+startdate+'&end_date='+enddate , { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }


    // on postal tab

    getPostaluser(roll_id, page, search, institution, course, degree, roll_no, status): Observable<any> {
        if (!search) {
            search = '';
        }
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/postal-users?page=`  + page  + '&search=' + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no + '&status=' + status).pipe(map(user => user));
    }

    getPostalcount( search,institution, course, degree): Observable<any> {
        
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/postal-users/counts?institution=`  + institution +'&search=' + search + '&course=' + course + '&degree=' + degree ).pipe(map(user => user));
    }

    updatePostalDateUser(userid:any,month,year) {
        var formData = {
            'month': month,
            'year': year,
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/postal-users/month-year-update/` + userid ,formData).pipe(map(user => {
            return user;
        }));
    }

    updatePostalUser(userid:any,status, reason) {
        var formData = {
            'status': status,
            'reason': reason
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/postal-users/institute-status/` + userid, formData).pipe(map(user => {
            return user;
        }));
    }

    updatePostalRoeUser(userid:any,status, reason) {
        var formData = {
            'status': status,
            'reason':reason
        }
        return this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/postal-users/roe-status/` + userid, formData).pipe(map(user => {
            return user;
        }));
    }

    downloadPostaluser( roll_id, search, institution, course, degree, roll_no,status): Observable<any> {
        const file =this.http.get<Blob>(`${environment.apiUrl}${environment.apiPrefix}/subadmin/postal-users/export-excel?search=` + search + '&institution=' + institution + '&course=' + course + '&degree=' + degree + '&roll_no=' + roll_no+ '&status=' + status, { responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    getSeat(id): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}${environment.apiPrefix}/staff/seatings/get-seats/`+ id).pipe(map(user => user));
    }

    downloadSeat(id,side): Observable<any> {
        var formData = new FormData();
        if(side){
        formData.append('seating',side);
        }
        const file =this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/staff/seatings/download-seats/`+id, formData,{ responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }

    downloadstickers(id,side): Observable<any> {
        var formData = new FormData();
        formData.append('seating',side);
        const file =this.http.post<any>(`${environment.apiUrl}${environment.apiPrefix}/staff/seatings/download-stickers/`+id, formData,{ responseType: 'blob' as 'json' }).pipe(map(user => {
            return user
        }));
        return file;
    }
}
