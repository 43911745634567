import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthSuperadminGuard, AuthVendorGuard } from './_guards';
// canActivate: [AuthSuperadminGuard],
const routes: Routes = [
 
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),canActivate: [AuthSuperadminGuard]},
  { path: 'seating-arrangements', loadChildren: () => import('./seating-arrangements/seating-arrangements.module').then(m => m.seatingarrangementsModule),canActivate: [AuthSuperadminGuard]},
  { path: 'images', loadChildren: () => import('./student-images/student-images.module').then(m => m.studentimagesModule),canActivate: [AuthSuperadminGuard]},
  
  { path: 'edit-registartion-list', loadChildren: () => import('./edit-registartion-list/edit-registartion-list.module').then(m => m.EditRegistartionListModule),canActivate: [AuthSuperadminGuard]},
  { path: 'reporting', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),canActivate: [AuthSuperadminGuard]},
  { path: 'on-postal', loadChildren: () => import('./on-postal/on-postal.module').then(m => m.OnPostalModule),canActivate: [AuthSuperadminGuard]},
  { path: 'edit-on-postal', loadChildren: () => import('./edit-on-postal/edit-on-postal.module').then(m => m.EditOnPostalModule),canActivate: [AuthSuperadminGuard]},
  { path: 'gallery', loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule),canActivate: [AuthSuperadminGuard]},
  { path: 'feeds', loadChildren: () => import('./feeds/feeds.module').then(m => m.FeedsModule),canActivate: [AuthSuperadminGuard]},
  { path: 'youtube-link', loadChildren: () => import('./youtube-link/youtube-link.module').then(m => m.YoutubeLinkModule),canActivate: [AuthSuperadminGuard]},
  { path: 'employee-food-registration', loadChildren: () => import('./employee-food-registration/employee-food-registration.module').then(m => m.EmployeeFoodRegistrationModule),canActivate: [AuthSuperadminGuard]},
  { path: 'postal-images', loadChildren: () => import('./postal-images/postal-images.module').then(m => m.PostalImagesModule),canActivate: [AuthSuperadminGuard]},

 
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
